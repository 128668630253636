import {ApiBase} from '@jetCommon/api/base';

export default class VideoTutorialCategoryApi extends ApiBase {
    static resourceName = 'video_tutorial_categories';

    updateOrder(data) {
        return this.apiPatch('order/', {
            video_tutorial_category_ids: data,
        });
    }
}
