import {ApiBase} from '@jetCommon/api/base';

export default class CompanyNoteApi extends ApiBase {
    static resourceName = 'company_notes';

    /**
     * Returns attachment's note url
     * @param {String} noteId
     * @param {String} attachmentId
     * @returns {String}
     */
    downloadAttachment(noteId, attachmentId) {
        return this.getApiUrlWithParams(`${noteId}/download_attachment/`, {attachment_id: attachmentId});
    }

    /**
     * Returns zip file url with all attachments of the note
     * @param {String} noteId
     * @returns {String}
     */
    downloadAttachments(noteId) {
        return this.getApiUrlWithParams(`${noteId}/download_zipped_attachments/`);
    }

    /**
     * Create a new note attachment
     * @param {String} noteId -> Id of the note to attach the file to
     * @param {{ uploaded_file_id: number }} data -> Id of the uploaded file to attach
     * @returns {void}
     */
    createAttachment(noteId, data) {
        return this.apiPost(`${noteId}/create_attachment/`, data);
    }

    /**
     * Delete a note attachment
     * @param {String} attachmentId -> Id of the attachment to delete
     * @returns {void}
     */
    deleteAttachment(attachmentId) {
        return this.apiDelete(`delete_attachment/`, {
            data: {attachment_id: attachmentId},
        });
    }

    /**
     * Get Payroll choices
     * @param {Object} params
     * @returns {Promise<[{ display_name: string, value: string | number }]>}
     */
    getPayrollChoices(params) {
        return this.apiGet('payroll_choices/', {params});
    }

    /**
     * Get Person choices
     * @param {Object} params
     * @returns {Promise<[{ display_name: string, value: string | number }]>}
     */
    getPersonChoices(params) {
        return this.apiGet('created_by_choices/', {params});
    }

    /**
     * Get Note Type choices
     * @returns {Promise<[{ display_name: string, value: string | number }]>}
     */
    getNoteTypeChoices() {
        return this.apiGet('note_type_choices/');
    }

    /**
     * Deletes a specific todo item from a company note.
     *
     * @param {string} noteId - The ID of the note containing the todo item.
     * @param {string} todoId - The ID of the todo item to be deleted.
     * @returns {Promise} - A promise that resolves when the todo item is successfully deleted.
     */
    deleteTodo(noteId, todoId) {
        return this.apiDelete(`${noteId}/company_todos/${todoId}/`);
    }

    /**
     * Clone a note for multiple companies
     * @param {String} noteId -> Id of the note to be cloned
     * @param {Array<Number>} companyIds -> List of company IDs to clone the note for
     * @returns {Promise<void>}
     */
    cloneNoteForCompanies(noteId, companyIds) {
        return this.apiPost('clone_for_companies/', {
            note_id: noteId,
            company_ids: companyIds,
        });
    }

    /**
     * Send notification for new onboarding note
     * @param {String} noteId -> Id of the note
     * @returns {Promise<void>}
     */
    notifyNewOnboardingNote(noteId) {
        return this.apiGet(`${noteId}/notify_new_onboarding_note/`);
    }
}
