import {end} from '@jetCommon/api';
import AbsenceRequestKindSettingsApiCommon from '@jetCommon/api/absence-request-kind-settings';
import AccountantApiCommon from '@jetCommon/api/accountant';
import AdminApi from '@/api/admin';
import AtecoApiCommon from '@jetCommon/data-stores-api/ateco';
import AuthApi from '@/api/auth';
import CCNLApiCommon from '@jetCommon/data-stores-api/ccnl';
import CompanyAbsenceRequestKindApiCommon from '@jetCommon/api/company-absence-request-kind';
import CompanyAddressApi from '@/api/company-address';
import CompanyApi from '@/api/company';
import CompanyBoardDocumentApiCommon from '@jetCommon/api/company-board-document';
import CompanyCCNLSettingsApiCommon from '@jetCommon/api/company-ccnl-settings';
import CompanyContactsApi from '@/api/company-contacts';
import CompanyDocumentApi from '@/api/company-document';
import CompanyGroupApi from '@/api/company-group';
import CompanyHeadquartersPolicyApi from '@jetCommon/api/company-headquarters-policy';
import CompanyNoteApi from '@/api/company-note';
import CompanyOnboardingApi from '@/api/company-onboarding';
import CompanyOnboardingTaskApi from '@/api/company-onboarding-task';
import CompanyOnboardingTaskDocumentApi from '@/api/company-onboarding-task-document';
import CompanyPaymentApi from '@/api/company-payment';
import CompanyPayslipExtraBonusKindApiCommon from '@jetCommon/api/company-payslip-extra-bonus-kind';
import CompanySalaryPaymentSettingsApi from '@/api/company-salary-payment-settings';
import CompanySettingsApi from '@/api/company-settings';
import CompanyWorkingHoursApiCommon from '@jetCommon/api/company-working-hours';
import ContractorApiCommon from '@jetCommon/api/contractor';
import EmployeeApi from '@/api/employee';
import EmployeeDocumentApi from '@/api/employee-document';
import EmployeeHiringApi from '@/api/employee-hiring';
import EmployeeWorkingHoursApiCommon from '@jetCommon/api/employee-working-hours';
import ExpenseApiCommon from '@jetCommon/api/expense';
import ExtraServiceApi from '@/api/extra-service';
import HiringApi from '@/api/hiring';
import HiringDocumentCommonApi from '@jetCommon/api/hiring-document';
import HolidaysApiCommon from '@jetCommon/api/holidays';
import JetAsyncTaskApi from '@/api/async-task';
import LabourConsultantApi from '@/api/labour-consultant';
import LocationApiCommon from '@jetCommon/api/location';
import OperatorApi from '@/api/operator';
import PayrollApi from '@/api/payroll';
import PayrollAttachmentsApiCommon from '@jetCommon/api/payroll-attachments';
import PayrollProvisionalApi from '@/api/payroll-provisional';
import PayslipApi from '@/api/payslip';
import PersonApiCommon from '@jetCommon/api/person';
import SetupApi from '@jetCommon/api/setup';
import SpidPasswordlessAuthenticationCommon from '@jetCommon/api/spid-passwordless-authentication';
import StudioApiCommon from '@jetCommon/api/studio/studio';
import StudioOperatorApiCommon from '@jetCommon/api/studio/studio-operator';
import TodosApi from '@/api/todo';
import ToolsApi from '@/api/tools';
import UploadedFilesApiCommon from '@jetCommon/api/uploadedFiles';
import VideoTutorialCategoryApi from '@/api/video-tutorial-category';
import VideoTutorialItemApi from '@/api/video-tutorial-item';
import WelfareFundApi from '@/api/welfare-fund';
import WelfareFundCompanyPaymentApi from '@/api/welfare-fund-company-payment';
import WelfareFundEmployeeMonthlyContributionApi from '@/api/welfare-fund-employee-monthly-contribution';

export const api = {
    end,
    absenceRequestKindSettings: new AbsenceRequestKindSettingsApiCommon(),
    accountants: new AccountantApiCommon(),
    admins: new AdminApi(),
    asyncTasks: new JetAsyncTaskApi(),
    auth: new AuthApi(),
    ateco: new AtecoApiCommon(),
    ccnl: new CCNLApiCommon(),
    companies: new CompanyApi(),
    companyAbsenceRequestKinds: new CompanyAbsenceRequestKindApiCommon(),
    companyAddresses: new CompanyAddressApi(),
    companyContacts: new CompanyContactsApi(),
    companyDocuments: new CompanyDocumentApi(),
    companyBoardDocuments: new CompanyBoardDocumentApiCommon(),
    companyCCNLSettings: new CompanyCCNLSettingsApiCommon(),
    companyGroups: new CompanyGroupApi(),
    companyHeadquartersPolicy: new CompanyHeadquartersPolicyApi(),
    companyNotes: new CompanyNoteApi(),
    companyOnboardingTaskDocuments: new CompanyOnboardingTaskDocumentApi(),
    companyOnboarding: new CompanyOnboardingApi(),
    companyOnboardingTasks: new CompanyOnboardingTaskApi(),
    companyPayments: new CompanyPaymentApi(),
    companyPayslipExtraBonusKinds: new CompanyPayslipExtraBonusKindApiCommon(),
    companySalaryPaymentSettings: new CompanySalaryPaymentSettingsApi(),
    companySettings: new CompanySettingsApi(),
    companyWorkingHours: new CompanyWorkingHoursApiCommon(),
    contractors: new ContractorApiCommon(),
    employeeDocuments: new EmployeeDocumentApi(),
    employeeHirings: new EmployeeHiringApi(),
    employeeWorkingHours: new EmployeeWorkingHoursApiCommon(),
    employees: new EmployeeApi(),
    expenses: new ExpenseApiCommon(),
    extraServices: new ExtraServiceApi(),
    hirings: new HiringApi(),
    hiringDocuments: new HiringDocumentCommonApi(),
    holidays: new HolidaysApiCommon(),
    labourConsultants: new LabourConsultantApi(),
    locations: new LocationApiCommon(),
    operators: new OperatorApi(),
    payrollAttachments: new PayrollAttachmentsApiCommon(),
    payrollProvisional: new PayrollProvisionalApi(),
    payrolls: new PayrollApi(),
    payslips: new PayslipApi(),
    persons: new PersonApiCommon(),
    setup: new SetupApi(),
    spidPasswordlessAuthentication: new SpidPasswordlessAuthenticationCommon(),
    studios: new StudioApiCommon(),
    studioOperators: new StudioOperatorApiCommon(),
    uploadedFiles: new UploadedFilesApiCommon(),
    companyTodos: new TodosApi(),
    tools: new ToolsApi(),
    videoTutorialCategory: new VideoTutorialCategoryApi(),
    videoTutorialItem: new VideoTutorialItemApi(),
    welfareFunds: new WelfareFundApi(),
    welfareFundCompanyPayments: new WelfareFundCompanyPaymentApi(),
    welfareFundEmployeeMonthlyContributions: new WelfareFundEmployeeMonthlyContributionApi(),
};
