import {ApiBase} from '@jetCommon/api/base';

export default class LabourConsultantApi extends ApiBase {
    static resourceName = 'labour_consultants';

    uploadAvatar(labourConsultantId, data) {
        return this.apiPostForm(`${labourConsultantId}/avatar/`, data);
    }

    removeAvatar(labourConsultantId) {
        return this.apiDelete(`${labourConsultantId}/avatar/`);
    }

    initInpsScraping(data) {
        return this.apiPost('init_inps_scraping/', data);
    }

    requestSync(labourConsultantId) {
        return this.apiPost(`${labourConsultantId}/request_sync/`);
    }
}
