import {StudioApiBase} from '@jetCommon/api/studio/base';

export default class StudioOperatorApiCommon extends StudioApiBase {
    static resourceName = 'studio_operators';

    getRoleChoices() {
        return this.apiGet('role_choices/');
    }

    sendRegistrationLink(id) {
        return this.apiPost(`${id}/send_registration_link/`);
    }

    uploadAvatar(studioOperatorId, data) {
        return this.apiPostForm(`${studioOperatorId}/avatar/`, data);
    }

    removeAvatar(studioOperatorId) {
        return this.apiDelete(`${studioOperatorId}/avatar/`);
    }
}
