import EmployeeDocumentApiCommon from '@jetCommon/api/employee-document';

export default class EmployeeDocumentApi extends EmployeeDocumentApiCommon {
    static resourceName = 'employee_documents';

    updateOrCreateCUDocument(config) {
        return this.apiPost('update_or_create_cu_document/', config);
    }

    bulkUpdateOrCreateCUDocument(config) {
        return this.apiPost('bulk_update_or_create_cu_document/', config);
    }

    sendCUsUploadedNotification(company, year) {
        return this.apiPost('send_cus_uploaded_notification/', {company, year});
    }
}
