import PayslipApiCommon from '@jetCommon/api/payslip';

export default class PayslipApi extends PayslipApiCommon {
    setAsLast(payslipId, isLastPayslip) {
        return this.apiPost(`${payslipId}/set_as_last/`, {
            is_last_payslip: isLastPayslip,
        });
    }

    getLastEmployeePayslip(params) {
        return this.apiGet('last_employee_payslip/', {params});
    }
}
