import HiringApiCommon from '@jetCommon/api/hiring';

export default class HiringApi extends HiringApiCommon {
    downloadReportUrl(params) {
        return this.getApiUrlWithParams('download_report/', params);
    }

    completeHiring(hiringId) {
        return this.apiPost(`${hiringId}/complete_hiring/`, {});
    }

    reopenHiring(hiringId) {
        return this.apiPost(`${hiringId}/reopen/`);
    }
}
