import EmployeeApiCommon from '@jetCommon/api/employee';

export default class EmployeeApi extends EmployeeApiCommon {
    createFullEmployment(data) {
        return this.apiPost('create_full_employment/', data);
    }

    updateFullEmployment(id, data) {
        return this.apiPost(`${id}/update_full_employment/`, data);
    }

    deleteFullEmployment(id, data) {
        return this.apiPost(`${id}/delete_full_employment/`, data);
    }

    contractSetHasLastPayslip(employeeId, payrollId, hasLastPayslip) {
        return this.apiPost(`${employeeId}/contract_set_has_last_payslip/`, {
            has_last_payslip: hasLastPayslip,
            payroll: payrollId,
        });
    }
}
