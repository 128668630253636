import {ApiBase} from '@jetCommon/api/base';

export default class CompanySettingsApi extends ApiBase {
    static resourceName = 'company_settings';

    getExtraMonthlyPayChoices() {
        return this.apiGet('extra_monthly_pay_choices/');
    }

    getAbsencesExpensesChoices() {
        return this.apiGet('absences_expenses_choices/');
    }

    getPayDayChoices() {
        return this.apiGet('pay_day_choices/');
    }

    getPayMonthChoices() {
        return this.apiGet('pay_month_choices/');
    }

    getDraftPayslipsSlaTimeChoices(featureFlagDraftPayslips) {
        return this.apiGet('draft_payslips_sla_time_choices/', {
            params: {feature_flag_draft_payslips: featureFlagDraftPayslips},
        });
    }

    getPayrollElaborationModeChoices() {
        return this.apiGet('payroll_elaboration_mode_choices/');
    }
}
