import {ApiBase} from '@jetCommon/api/base';

export default class OperatorApi extends ApiBase {
    static resourceName = 'operators';

    uploadAvatar(operatorId, data) {
        return this.apiPostForm(`${operatorId}/avatar/`, data);
    }

    removeAvatar(operatorId) {
        return this.apiDelete(`${operatorId}/avatar/`);
    }
}
