import {ApiBase} from '@jetCommon/api/base';

export default class ToolsApi extends ApiBase {
    static resourceName = 'tools';

    validateUnilav(unilavFile) {
        const form = new FormData();
        form.append('file', unilavFile);
        return this.apiPost('unilav/validate/', form);
    }

    notifyLabourConsultant(payload) {
        const form = new FormData();
        for (const [k, v] of Object.entries(payload)) {
            form.append(k, v ?? '');
        }

        return this.apiPost('unilav/notify_labour_consultant/', form);
    }
}
