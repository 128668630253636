import CompanyOnboardingTaskCommon from '@jetCommon/api/company-onboarding-task';

export default class CompanyOnboardingTaskApi extends CompanyOnboardingTaskCommon {
    setup(id, data) {
        return this.apiPost(`${id}/setup/`, data);
    }

    submitData(id, data) {
        return this.apiPost(`${id}/submit_data/`, data);
    }

    approval(id, data) {
        return this.apiPost(`${id}/approval/`, data);
    }

    markAsCompleted(id) {
        return this.apiPost(`${id}/set_completion_status/`, {is_completed: true});
    }

    markAsUncompleted(id) {
        return this.apiPost(`${id}/set_completion_status/`, {is_completed: false});
    }
}
